<template>
  <div class="Banner">
    <el-container>
        <el-header class="Header">
            <div class="Index" @click="BackToIndex">首页</div>
            <div class="UserInfo">
                <div class="UserAvatar"><img :src="userinfo.icon"></div>
                <div class="InfoPanel">
                    <div class="Username">
                        {{userinfo.nickname}}
                    </div>
                    <div class="InfoBanner">
                        <div class="UserCenter" @click="ToUserCenter">
                            <i class="el-icon-user-solid mark"></i>个人中心
                        </div>
                        <div class="ContentMP" @click="ToMp">
                            <i class="el-icon-s-management mark"></i>创作中心
                        </div>
                    </div>
                </div>
            </div>
        </el-header>
        <el-container>
            <el-aside width="250px">Aside</el-aside>
            <el-main>Main</el-main>
        </el-container>
    </el-container>
  </div>
</template>

<script>
import {GetUserNickName} from "@/api/user.js"
export default {
    name:'ArticleManage',
    data(){
        return{
            userinfo:''
        }
    },
    created(){
        GetUserNickName().then(res=>{
            this.userinfo = res.data
        })
    },
    mounted(){

    },
    methods:{
        BackToIndex(){
            this.$router.push({
                path:'/index'
            })
        },
        ToUserCenter(){
            this.$router.push({path:'/account/usercenter'})
        },
        ToMp(){
            this.$router.push({path:'/account/editingcenter'})
        },
    }
}
</script>

<style scoped>
.Header{
    display: flex;
    width: 100%;
    align-items: center;
    line-height: 50px;
    height: 50px !important;
    box-shadow: 0px 5px 50px 0px rgb(0 0 0 / 10%);
    position: sticky;
    top: 0;
    background: #ffffff;
    padding: 0 !important;
    z-index: 20;
}
.Index{
    width: 90px;
    padding: 0px 5px;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
}
.Index:hover{
    background-color: #dddddd;
}
/* .LogoName{
    width: 90px;
    padding: 0px 5px;
    font-size: 18px;
    font-weight: 500;
    cursor: default;
} */
.UserInfo{
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    right: 55px;
    width: 90px;
    height: 40px;
    background-color: rgb(255, 255, 255);
    cursor: pointer;
}
.UserAvatar{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.UserInfo:hover img{
    margin-top: 15px;
    transform:scale(1.5);
    z-index: 20;
}
.UserAvatar img{
    position: absolute;
    width: 40px;
    height: 100%;
    border-radius: 20px;
}
.UserInfo:hover .InfoPanel{
    display: block;
}
.InfoPanel{
    position: absolute;
    display: none;
    width: 200px;
    height: 300px;
    top:45px;
    background-color: white;
    box-shadow: 0px 5px 50px 0px rgb(0 0 0 / 10%);
}
.Username{
    width: 100%;
    height: 20px;
    padding: 10px 0px 20px 0px;
    font-size: 15px;
    font-weight: 600;
    border-bottom: 1px solid #e8e8ed;
    font-family: 'PingFang SC','SF Pro Display','Microsoft YaHei',Roboto,Noto,Arial,sans-serif;
}
.InfoBanner{
    padding: 5px 0px 0px 0px ;
}
.UserCenter,.ContentMP{
    padding: 5px 0px 0px 10px;
    height: 30px;
    line-height: 30px;
    font-size: 15px;
    text-align: left;
    color: gray;
    /* cursor: pointer; */
}
.mark{
    padding: 0px 8px 0px 0px;
}
/* .ContentMP{
    padding: 5px 0;
    height: 30px;
    line-height: 30px;
    font-size: 16px;
    text-align: left;
} */
.UserCenter:hover,.ContentMP:hover{
    background-color: #dddddd;
}
</style>